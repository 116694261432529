@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --LightColor: #ffffff;
  --DarkColor: #000000;
  --MainColor: #443c76;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input,
input::placeholder {
  font-family: 'Raleway', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Raleway', sans-serif;
}

.mainHeading {
  font-size: 4rem !important;
}
@media only screen and (max-width: 575px) {
  .mainHeading {
    font-size: 2rem !important;
  }
}

.ant-dropdown .ant-dropdown-menu {
  height: 100%;
  max-height: 420px;
  overflow-y: auto;
  box-shadow: 0 3px 8px -10px rgb(0 0 0 / 12%),
    5px 0px 16px -6px rgb(0 0 0 / 8%), 0 0 0 0 rgb(0 0 0 / 5%);
}
.ant-dropdown .ant-dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.ant-dropdown .ant-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: var(--LightColor);
  border: unset;
  border-radius: 10px;
}
.ant-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}
.ant-dropdown-menu-submenu-arrow-icon {
  display: none !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: unset !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 0.5rem 0.75rem 0 !important;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 !important;
}
.ant-dropdown-trigger {
  text-decoration: none;
}

.loadingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.patchLoader {
  height: 40vh;
}
.secondaryHeading {
  font-size: 1.7rem;
  font-weight: 600;
}

.dropMenu {
  width: 230px;
}
.submitLink {
  text-decoration: none;
  width: 100%;
  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(36, 0, 255, 0.58);
}
.submitLink svg,
.submitLink img {
  width: 25px;
  height: 25px;
  color: var(--MainColor);
}
.submitLink > span:last-child {
  font-size: 1rem;
  color: var(--MainColor);
  padding-left: 0.75rem;
}

iframe {
  display: none;
}

@page {
  /* size: A4; */
  margin: 20px;
}

@media print {
  .pagebreak {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  /* .pagebreak {
    page-break-before: always;
  } */
}

html {
  -webkit-print-color-adjust: exact;
}

.imageContainer .shopContainer {
  opacity: 1;
  position: absolute;
  z-index: 999;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: rgb(68, 60, 118);
  display: flex;
  align-items: center;
  background: white;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid rgb(68, 60, 118);
  font-size: 0.8em;
}
.imageContainer:hover .shopContainer {
  opacity: 1;
}

.imageContainer .wantIcon,
.imageContainer .collectionIcon {
  font-size: 1.1em;
  color: black;
  position: absolute;
  bottom: 28px;
  right: 15px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: 1px solid #443c76;
}

.imageContainer .collectionIcon {
  left: 15px;
  right: unset;
}

.ant-modal-header,
.ant-modal-footer {
  display: none;
}

.imageGuidelineModal .ant-modal-content,
.imageGuidelineModal.ant-modal {
  width: 1000px !important;
}

.ant-dropdown a {
  text-decoration: none;
}
